<template>
    <nav>
        <router-link :to="{name: 'homepage'}">
            <font-awesome-icon icon="home"/>
            Accueil
        </router-link>
        &middot;
        <router-link :to="{name: 'about'}">
            <font-awesome-icon :icon="['far', 'envelope']"/>
            Contact
        </router-link>
        &middot;
        <router-link :to="{name: 'list_jeux_livres'}">
            <font-awesome-icon icon="chess"/>
            Nos jeux et livres
        </router-link>
        &middot;
        <router-link :to="{name: 'list_evenements'}">
            <font-awesome-icon icon="calendar-day"/>
            Événements
        </router-link>
        &middot;
        <router-link :to="{name: 'calendar'}">
            <font-awesome-icon :icon="['far', 'calendar-alt']"/>
            Calendrier
        </router-link>
        &middot;
        <router-link :to="{name: 'wiki'}">
            <font-awesome-icon :icon="['far', 'bookmark']"/>
            Wiki
        </router-link>
        &middot;
        <router-link :to="{name: 'dashboard_home'}">
            <font-awesome-icon :icon="['far', 'user']"/>
            Espace membre
        </router-link>
        <template v-if="isLoggedIn()">
            &middot;
            <router-link :to="{name: 'logout'}">
                <i class="ni ni-button-power"></i>
                Déconnexion
            </router-link>
        </template>
    </nav>
</template>

<script>
    import {isLoggedIn} from '@/util/auth';

    export default {
        name: "ThinNavbar",
        methods: {isLoggedIn}
    }
</script>

<style scoped lang="scss">
    @import "@/css/_thin_white_links";

    nav {
        z-index: 2; // Otherwise it gets behind cards padding
    }

    .ni {
        line-height: 1.5;
        vertical-align: bottom;
    }
</style>